/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // mobile dropdown navigation styles - MAIN NAV
        $('#navbarResponsive').on('shown.bs.collapse', function () {
          $('button.navbar-toggler.ready').removeClass('ready');
        });

        $('.search-bar .icon').on('click', function() {
          $(this).parent().toggleClass('active');
        });

        $('.tiles:not(.featured-tiles,.search-tiles) .tile article header').matchHeight();
        $('.tiles:not(.featured-tiles,.search-tiles) .tile article .entry-summary').matchHeight();


        $('.testimonials-wrap').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 400,
          fade: true,
          cssEase: 'linear',
          rows: 0
        });

        $('.profile-content').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.profile-thumbs,.profile-img',
          autoplay: true,
          autoplaySpeed: 10000,
          speed: 750
        });
        $('.profile-img').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.profile-thumbs,.profile-content',
          autoplay: true,
          autoplaySpeed: 10000,
          speed: 750
        });
        $('.profile-thumbs').slick({
          slidesToShow: $('.profile-thumbs').data('count'),
          slidesToScroll: 1,
          asNavFor: '.profile-content,.profile-img',
          dots: false,
          centerMode: true,
          speed: 750,
          focusOnSelect: true
        });
        $('.featured-tiles').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 400,
          fade: true,
          cssEase: 'linear'
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

/*
        $('.home-tiles .tile article header').matchHeight();
        $('.home-tiles .tile article .entry-summary').matchHeight();
*/

        $('.home-tiles').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 400,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Events page
    'events': {
      init: function() {
        $('#view-all-events').on('click', function (e) {
          $('.event-tile').removeClass('hidden');
          $(this).hide();
          return false;
        });
      }
    },
    // Search page
    'search': {
      init: function() {
        $('#menu-primary-navigation .nav-item-39').removeClass('current_page_parent');
      }
    },
    // Lit Activities page
    'literacy_activities': {
      init: function() {
        $('.taxonomy-display li .btn').on('click', function (e) {
          var displayTile = $(this).attr('id');
          $('.taxonomy-display li .btn').removeClass('on');
          $(this).addClass('on');
          $('.activity-tile').addClass('hidden');
          $('.activity-tile.'+displayTile).removeClass('hidden');
          return false;
        });
      }
    },
    // Presenters page
    'presenters': {
      init: function() {
        $('.taxonomy-display li .btn').on('click', function (e) {
          var displayTile = $(this).attr('id');
          $('.taxonomy-display li .btn').removeClass('on');
          $(this).addClass('on');
          $('.individuals .individual').addClass('hidden');
          $('.individuals .individual.'+displayTile).removeClass('hidden');
          return false;
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
